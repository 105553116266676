import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../Single/SectionTitle';
import ViewMore from '../../Single/ViewMore';
import MobileProject from './MobileProject';

function Mobile(props){
    const priorityArray = []
    for(let i=0; i< 4; i++){
        priorityArray[i] = props.mobileData[i]
    }
    return(
        <React.Fragment>
           <div className="container">
               <div className="row">
                   <SectionTitle title="Mobile Apps & UI/UX" paragraph="Good Development, Better Designs." />
                {priorityArray.map((i) => (
                    <div className="col-lg-6" key={i.id} style={{paddingLeft:"8px", paddingRight:"8px"}}>
                        <MobileProject category={i.category} name={i.name} up={i.images[0]} down={i.images[1]} id={i.id}/>
                    </div>
                ))}
                <div className="col-12">
                    <Link to={{
                        pathname:`portfolio/mobile-apps`,
                        state:`Mobile App Development`
                    }}>
                        <ViewMore />
                    </Link>
                </div>
               </div>
           </div>
        </React.Fragment>
    )
}

export default Mobile;