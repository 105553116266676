import React, {Fragment, useEffect, useState} from 'react';
import _ from 'lodash'
import Loader from '../../Single/Loader';
import Team from './Team';

function DreamTeam(){
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)

    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/members/`;

    useEffect(() => {
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            const dataSort = _.orderBy(data, ['id'], ['asc']);
            setData(dataSort)
            setLoader(false)
        }
        fetchData()
    }, [])

    if(loader){
        return(
            <Loader />
        )
    }
    return(
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-headings">
                            <h1>
                                Dream Team
                            </h1>
                            <p>
                                Real Gs move in silence like Lasagna. Google it?
                            </p>
                        </div>
                    </div>
                    {data.map((i) => (
                        <div className="col-lg-6" key={i.id} style={{paddingLeft:"8px", paddingRight:"8px"}}>
                            <Team 
                            id={i.id} 
                            role={i.role} 
                            name={i.name} 
                            img={i.images[1]}
                            hoverImg={i.images[0]}
                            // behance={i.behance}
                            email={i.email}
                            // fiverr={i.fiverr}
                            // github={i.github}
                            // instagram={i.instagram}
                            linkedin={i.linkedin}
                            // upwork={i.upwork}
                            website={i.website}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default DreamTeam;
