import React from 'react';


function SectionTitle(props){
    return(
        <div className="col-12">
            <div className="section-headings">
                <h1>
                    {props.title}
                </h1>
                <p>
                    {props.paragraph}
                </p>
            </div>
        </div>
    )
}
export default SectionTitle;