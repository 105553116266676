import { from } from '@apollo/client';
import React from 'react';
import {Link} from 'react-router-dom'
function MobileProject(props){
    return(
        <React.Fragment>
            <Link 
                to={{
                    pathname:`portfolio/mobile-apps/${props.id}`,
                    state:`${props.id}`,
                }}>
                <div className="mobile">
                    <p className="slide-tr">
                        {props.category}
                    </p>
                    <h2 className="slide-tr">
                        {props.name}
                    </h2>
                    <h6 className="slide-tr">
                        Learn More
                    </h6>
                    <img src={props.up} className="up-img slide-in-top" alt="mobile" />
                    <img src={props.down} className="down-img slide-in-down" alt="mobile" />
                </div>
            </Link>
        </React.Fragment>
    )
}

export default MobileProject;