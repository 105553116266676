import React, {useState, useEffect} from 'react';
import Under from '../Single/Under';
import Loader from '../Single/Loader';
import './about.css';
import DreamTeam from '../HomePage/Team/DreamTeam';
import ClientsComplete from '../HomePage/ClientsComplete';
import Values from '../Single/Values';
import Contact from '../Contacts/Contact';
function About(){
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        document.title = "Sinecure | About"
        setTimeout(() => {
            setLoader(true)
        }, 2000)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <div className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="section-headings" style={{textAlign:"left"}}>
                            Meet
                            <span className="span-text">
                                Sinecure.
                            </span>
                        </h1>
                    </div>
                    <div className="col-12" style={{textAlign:"left"}}>
                        <p className="about-text-special">
                        Your dream work. Our team work.
                        </p>
                        <p className="about-text">
                            Sinecure is an idea that shapes your thoughts into realities using cutting edge technologies and new market trends.
                            We are a matrix-based organization with full-service technology and crisp on evolving technology trends for better serving our clients. 
                        </p>
                        <p className="about-text">
                            With our mature and substantial team, gigantic technology container, better quality at a cheaper price, and on-time delivery, we compile solutions for our clients to attain the most suitable solution between technology and their business. 
                        </p>
                        <p className="about-text">
                            We particularize in Enterprise Application Development, Website Development (Any sort), Mobile Application Development (Android & iOS), Amazon Web Services, Search Engine Optimization (SEO), Social Media Marketing (SMM), Business Intelligence Solutions for markets (Food, Fashion, Footwear, Household, Textile, Construction, Electronics, Cosmetics).
                        </p>
                    </div>
                </div>
            </div>
            <DreamTeam />
            <ClientsComplete />
            <Values />
            <Contact />
        </div>
    )
}

export default About;