import React from 'react';

function ViewMore(){
    return(
        <div style={{textAlign:"center"}}>
            <button className="view-more">
                View More
            </button>
        </div>
    )
}

export default ViewMore;