import React, {useEffect, useState} from 'react';
import PortfolioMainTabs from './PortfolioMainTabs';
import {Link} from 'react-router-dom';
import Loader from '../Single/Loader';
function PortfolioMain({match}){
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/categories/`;

    useEffect(() => {
        document.title = "Sinecure | Portfolio"
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setData(data)
            setLoader(false)
        }
        fetchData()
    }, [])
    if(loader){
        return <Loader />
    }
    return(
        <div className="portfolio">
            <div className="container" style={{maxWidth:"100%",paddingRight:"0px", paddingLeft:"0px" }}>
                <div className="row" style={{marginRight:"0px", marginLeft:"0px" }}>
                    <div className="cross-animate">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cross-animate.svg`} alt="cross" />
                    </div>
                    <div className="col-12">
                        <PortfolioMainTabs props={match} data={data} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioMain;