import React, {useState, useEffect} from 'react';
import Banner from './Banner';
import Technologies from './Technologies';
import WebProjects from './WebProjects'
import Mobile from './Mobile/Mobile';
import Architecture from './Architecture/Architecture';
// import Header from '../Header/Header';
import Clients from './Clients';
import DreamTeam from './Team/DreamTeam';
// import Footer from '../Single/Footer'
import Loader from '../Single/Loader'
import ClientsComplete from './ClientsComplete';
function Home(props){
    const [websiteData, setWebsiteData] = useState([])
    const [mobileData, setMobileData] = useState([])
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const websiteURL = `https://apis.sinecure.xyz/projectsWithCategory/Website Development`;
    const mobileURL = `https://apis.sinecure.xyz/projectsWithCategory/Mobile App Development`;
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        async function fetchData(){
            const dataWeb = await fetch(websiteURL, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await dataWeb.json();
            const dataMob = await fetch(mobileURL, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data2 = await dataMob.json();
            // const priorityArray = []
            // for(let i=0; i< 4; i++){
            //     priorityArray[i] = data[i]
            // }
            setWebsiteData(data)
            setMobileData(data2)
            setLoader(false)
        }
        fetchData()
    }, [])

    if(loader){
        return <Loader />
    }
    return(
        <React.Fragment>
            {/* <Header /> */}
            {/* <Loader /> */}
            <div className="container" style={{maxWidth:"100%",paddingRight:"0px", paddingLeft:"0px" }}>
                <div className="row" style={{marginRight:"0px", marginLeft:"0px" }}>
                    <Banner />
                </div>
            </div>
            <div style={{display:"block"}}>
                <div className="container background-technology" style={{maxWidth:"100%"}}>
                    <div className="row" style={{marginRight:"0px", marginLeft:"0px" }}>
                        <Technologies/>
                    </div>
                </div>
                <WebProjects websiteData={websiteData} />
                <Mobile mobileData={mobileData} />
                <Architecture />
                <Clients />
                <ClientsComplete />
                <DreamTeam />
            </div>
        </React.Fragment>
    )
}

export default Home;