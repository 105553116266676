import React from 'react';
import TechPara from '../Single/TechPara';

const myTechObj = [
    {
        id:0,
        url:`${process.env.PUBLIC_URL}/img/icons/ejs.webp`
    },
    {
        id:1,
        url:`${process.env.PUBLIC_URL}/img/icons/vuejs.webp`
    },
    {
        id:2,
        url:`${process.env.PUBLIC_URL}/img/icons/wordpress.webp`
    },
    {
        id:3,
        url:`${process.env.PUBLIC_URL}/img/icons/css.webp`
    }
]
// console.log(myTechObj)

function Technologies(){
    return(
        <React.Fragment>
            <TechPara />
                <div className="col-lg-6 tech-div-right margin-bottom-80 height-40">
                    <div>
                        <img src={myTechObj[2].url} className="tech_icons" alt="icon_down" />
                        <img src={myTechObj[1].url} className="tech_icons" alt="icon_down" />
                        <img src={myTechObj[0].url} className="tech_icons" alt="icon_down" />
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/img/icons/css.webp`} className="tech_icons" alt="icon_down" />
                        <img src={`${process.env.PUBLIC_URL}/img/icons/html.webp`} className="tech_icons" alt="icon_down" />
                        <img src={`${process.env.PUBLIC_URL}/img/icons/bulma.webp`} className="tech_icons" alt="icon_down" />
                    </div>
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/img/icons/sass.webp`} className="tech_icons" alt="icon_down" />
                        <img src={`${process.env.PUBLIC_URL}/img/icons/react.webp`} className="tech_icons" alt="icon_down" />
                        <img src={`${process.env.PUBLIC_URL}/img/icons/js.webp`} className="tech_icons" alt="icon_down" />
                    </div>
                </div>
                <div className="col-lg-6 tech-lines padding-left-5 height-40-heading">
                    <h2 className="tech-heading-left">
                        Front End Development
                    </h2>
                    <p className="tech-paragraph-left">
                        Everything is responsive!<br />Pixel Perfect too!
                    </p>
                </div>
                <div className="col-12">
                    <div className="grey-line"></div>
                </div>



            <div className="col-lg-6 tech-lines push-to-right padding-right-5 height-40-heading">
                <h2 className="tech-heading">
                    Back End Development.
                </h2>
                <p className="tech-paragraph">
                    We are also looking for the right language.<br />But we will still do, whatever you ask for.
                </p>
            </div>
            <div className="col-lg-6 tech-div-left margin-bottom-80 height-40">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/mysql.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/node.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/django.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/laravel.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/cnet.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/mongo.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/java.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/python.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/ruby.webp`} className="tech_icons" alt="icon_down" />
                </div>
            </div>
            <div className="col-12">
                <div className="grey-line"></div>
            </div>



            <div className="col-lg-6 tech-div-right margin-bottom-80 height-40 small-margin">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/sketch.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/xd.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/photoshop.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/ai.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/animate.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/figma.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/3dmax.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/lumion.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/vray.webp`} className="tech_icons" alt="icon_down" />
                </div>
            </div>
            <div className="col-lg-6 tech-lines padding-left-5 height-40-heading">
                <h2 className="tech-heading-left">
                    UI/UX & 3D Rendering.
                </h2>
                <p className="tech-paragraph-left">
                    We don’t follow trends, we set them.<br />Design that fit needs, not moods.
                </p>
            </div>
            <div className="col-12">
                <div className="grey-line"></div>
            </div>



            <div className="col-lg-6 tech-lines push-to-right padding-right-5 height-40-heading">
                <h2 className="tech-heading">
                    Dev Ops Engineering.
                </h2>
                <p className="tech-paragraph">
                    Servers don’t stop, Sinecure never rests.<br />If they do, we fix them.
                </p>
            </div>
            <div className="col-lg-6 tech-div-left margin-bottom-80 height-40">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/quicksight.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/athena.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/aws.webp`} className="tech_icons" alt="icon_down" />
                    
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/ec2.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/docker.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/digitalocean.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/jenkins.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/lambda.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/s3.webp`} className="tech_icons" alt="icon_down" />
                </div>
            </div>
            <div className="col-12">
                <div className="grey-line"></div>
            </div>



            <div className="col-lg-6 tech-div-right margin-bottom-80 height-30 small-margin">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/flutter.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/react.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/kotlin.webp`} className="tech_icons" alt="icon_down" />
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/firebase.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/android.webp`} className="tech_icons" alt="icon_down" />
                    <img src={`${process.env.PUBLIC_URL}/img/icons/swift.webp`} className="tech_icons" alt="icon_down" />
                </div>
            </div>
            <div className="col-lg-6 tech-lines padding-left-5 height-27">
                <h2 className="tech-heading-left">
                    Mobile App Development.
                </h2>
                <p className="tech-paragraph-left">
                    What’s common between a Bugatti and our Apps?<br />They are efficient, fast and never crash.
                </p>
            </div>
            <div className="col-12">
                <div className="grey-line"></div>
            </div>
        </React.Fragment>
    )
}

export default Technologies;