import React from 'react';


function SmallFooter(){
    return(
        <div>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <p>
                            info@sinecure.xyz
                        </p>
                        <div className="spacer"></div>
                        <p>
                            2021 Sinecure. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SmallFooter;