import React from 'react';


function Team(props){
    // function checkSampleBehance(behance){
    //     if(!behance.includes('sample')){
    //         return(
    //             <div className="circle">
    //                 <a href={props.behance} target="_blank" rel="noopener noreferrer">
    //                     <img src={`${process.env.PUBLIC_URL}/img/icons/behance-icon.svg`} alt="icon" />
    //                 </a>
    //             </div>
    //         )   
    //     }
    //     else{return false}
    // }
    // function checkSampleInstagram(instagram){
    //     if(!instagram.includes('sample')){
    //         return(
    //             <div className="circle">
    //                 <a href={props.instagram} target="_blank" rel="noopener noreferrer">
    //                     <img src={`${process.env.PUBLIC_URL}/img/icons/instagram-icon.svg`} alt="icon" />
    //                 </a>
    //             </div>
    //         )   
    //     }
    // }
    // function checkSampleGithub(github){
    //     if(!github.includes('sample')){
    //         return(
    //             <div className="circle">
    //                 <a href={props.github} target="_blank" rel="noopener noreferrer">
    //                     <img src={`${process.env.PUBLIC_URL}/img/icons/github-icon.svg`} alt="icon" />
    //                 </a>
    //             </div>
    //         )   
    //     }
    // }
    function checkSampleLinkedin(linkedin){
        if(!linkedin.includes('sample')){
            return(
                <div className="circle">
                    <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/linkedin-icon.svg`} alt="icon" />
                    </a>
                </div>
            )
        }
    }
    function checkWebsite(website){
        if(!website.includes('sample')){
            const websiteName = website.slice(8)
            return(
                <a href={website} target="_blank" rel="noopener noreferrer">
                    <p>
                        {websiteName}
                    </p>
                </a>
            )
        }
        else{
            return false;
        }
    }
    // function checkMail(a){
    //     if(a.name === "Mail"){
    //         return(
    //             <a href={`mailto:${a.url}`} target="_blank" rel="noopener noreferrer">
    //                 <img key={a.id} src={a.iconURL} alt="icon" />
    //             </a>
    //         )
    //     }
    //     else{
    //         return(
    //             <a href={a.url} target="_blank" rel="noopener noreferrer">
    //                 <img key={a.id} src={a.iconURL} alt="icon" />
    //             </a>
    //         )
    //     }
    // }
    return(
        <div className="team" style={{height:`${props.height}`}} key={props.id}>
            <h3>
                {props.name}
            </h3>
            <h6>
                {props.role}
            </h6>
            {/* <div className="social-icons">
                {props.icons.map((a) => (
                    <div className="circle" key={a.id}>
                        {checkMail(a)}
                    </div>
                ))}
            </div> */}
            <div className="social-icons">
                {/* <div className="circle">
                    <a href={props.upwork} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/upwork-icon.svg`} alt="icon" />
                    </a>
                </div> */}
                {/* <div className="circle">
                    <a href={props.fiverr} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/fiverr-icon.svg`} alt="icon" />
                    </a>
                </div> */}
                {checkSampleLinkedin(props.linkedin)}
                <div className="circle">
                    <a href={`mailto:${props.email}`} target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/mail-icon.svg`} alt="icon" />
                    </a>
                </div>
                {/* {checkSampleBehance(props.behance)} */}
                {/* {checkSampleGithub(props.github)} */}
                {/* {checkSampleInstagram(props.instagram)} */}
            </div>
            {checkWebsite(props.website)}
            <div className="role-picture">
                <img src={props.img} alt="leader" />
            </div>
            <div className="hover-picture">
                <img src={props.hoverImg} alt="leader" />
            </div>
        </div>
    )
}

export default Team;