import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Single/Loader';

function PortfolioArchitectureLayout({match, location}){
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)
    const [indexArray, setIndexArray] = useState([])
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/projectsWithCategory/${location.state}`;

    useEffect(() => {
        document.title = "Sinecure | Architecture"
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setData(data)
            const tempArr = [];
            for(let i = 0; i < data.length; i++){
                tempArr.push(data[i].id)
            }
            setIndexArray(tempArr)
            setLoader(false)
        }
        fetchData()
    }, [])

    function loopArchitecture(d){
        const images = d.images;
        const newArray = [];
        for(let i =1; i < images.length; i++){
            newArray.push(images[i])
        }
        return(
            <React.Fragment>
                <div className="architecture-layout-2">
                    <img src={newArray[0]} alt="img" className="main-img" />
                </div>
                <div className="architecture-layout-2">
                    <img src={newArray[1]} alt="img" className="main-img" />
                </div>
                <div className="architecture-layout-2">
                    <img src={newArray[2]} alt="img" className="main-img" />
                </div>
                <div className="architecture-layout-2">
                    <img src={newArray[3]} alt="img" className="main-img" />
                </div>
                <div className="architecture-layout-2">
                    <img src={newArray[4]} alt="img" className="main-img" />
                </div>
                <div className="architecture-layout-2">
                    <img src={newArray[5]} alt="img" className="main-img" />
                </div>
            </React.Fragment>
        )
        
    }
    if(loader){
        return <Loader />
    }

    return(
        <React.Fragment>
            <div className="container" style={{maxWidth:"100%"}}>
                <div className="row">
                    <div className="col-12" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                        <div className="portfolio-main-box" id="architecture">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <h1 className="portfolio-text">
                                    Architecture
                                </h1>
                                <h1 className="portfolio-text-second">
                                    Visualization<span className="portfolio-text-span">.</span>
                                </h1>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop:"100px", marginBottom:"100px"}}>
                <div className="container">
                    <div className="row">
                        {data.map((d => (
                            <React.Fragment key={d.id}>
                                <div className="col-lg-12" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                                    <div className="architecture-layout">
                                        <p>
                                            {d.category}
                                        </p>
                                        <h2>
                                            {d.name}
                                        </h2>
                                        <img src={d.images[0]} alt="websitelayouts" className="main-img" />
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                    <div className="architecture">
                                        <h6 className="architecture-heading">
                                            Description
                                        </h6>
                                        <p className="architecture-description">
                                            {d.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                                <div className="col-12">
                                {loopArchitecture(d)}
                                    
                                </div>
                            </React.Fragment>
                        )))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PortfolioArchitectureLayout;