import React, {useState, useEffect} from 'react';
import './contact.css'
import Under from '../Single/Under';
import Loader from '../Single/Loader';
import {useHistory} from 'react-router-dom'
import CheckBox from '../Single/CheckBox';
function Contact(){
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const apikey = "30f5705a-6d48-4bf1-88f0-359de0834b41"
    const url = "https://api.web3forms.com/submit"
    let history = useHistory();

    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleName = (e) => {
        setName(e.target.value)
    }
    const overlayFunctionOn = () => {
        document.getElementById("form-overlay").style.display = "block"
        document.getElementById("loader").style.display = "flex"
    }
    const overlayFunctionOff = () => {
        document.getElementById("form-overlay").style.display = "none"
    }
    const checkBoxFunction = () => {
        document.getElementById("loader").style.display = "none"
        document.getElementById("success").style.display = "block"
    }
    
    useEffect(() => {
        document.title = "Sinecure | Contact"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])
    const SubmitForm = async(e) => {
        e.preventDefault();
        const headers = {
            method:'POST',
            body:JSON.stringify({ name, email, message, apikey}),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        }
        const membersData = await fetch(url,headers)
        const data = await membersData.json();
        console.log(data)
        if(data.success === true){
            return(
                <React.Fragment>
                    {checkBoxFunction()}
                </React.Fragment>
            )
        }
    }
    if(!loader){
        return <Loader />
    }
    return(
        <div className="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{width:"100%", height:"auto", margin:"20px 0px"}}>
                            <img src={`${process.env.PUBLIC_URL}/img/icons/pakistan-bg2.svg`} className="map-image" alt="value-image" />
                        </div>
                        {/* <div className="row center-image">
                        </div> */}
                    </div>
                    <div className="col-12">
                        <div className="section-headings">
                            <h1>
                                Connect with Us
                            </h1>
                            <p>
                                Let's have a coffee?
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div id="form-overlay" onClick={overlayFunctionOff}>
                                <div className="loader" id="loader" style={{display:"none"}}>
                                    <div className="loader-mini">
                                    </div>
                                </div>
                                <div style={{display:"none"}} id="success">
                                        <CheckBox />
                                    </div>
                            </div>
                        <form onSubmit={SubmitForm} >
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Name</label>
                                    <input 
                                        type="name" 
                                        name="name"
                                        value={name}
                                        className="form-control"
                                        onChange={handleName}
                                        placeholder="Enter Your Name"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={email}
                                        className="form-control"
                                        onChange={handleEmail}
                                        placeholder="Enter Your Email"
                                        required 
                                    />
                                </div>
                                
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputPassword4">Your Message</label>
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        value={message}
                                        onChange={handleMessage}
                                        placeholder="Enter message"
                                        rows="5"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex-end">
                                <button className="button-submit" type="submit" onClick={overlayFunctionOn}>Send</button>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Contact;