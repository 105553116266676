import React from 'react';
import {Link} from 'react-router-dom'
import WebsiteProjectLayout from './WebsiteProjectLayouts';
import SectionTitle from '../Single/SectionTitle';
import ViewMore from '../Single/ViewMore';
function WebProjects(props){
    const priorityArray = []
    for(let i=0; i< 4; i++){
        priorityArray[i] = props.websiteData[i]
    }
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <SectionTitle title="Website Projects" paragraph="We know you might be looking for them." />
                    {priorityArray.map((i) => (
                        <div className="col-lg-6" key={i.id} style={{paddingLeft:"8px", paddingRight:"8px"}}>
                            <WebsiteProjectLayout image={i.images[0]} category={i.category} projectName={i.name} id={i.id} />
                        </div>
                    ))}
                </div>
                <div className="col-12">
                    <Link to={{
                        pathname:`portfolio/websites`,
                        state:`Website Development`
                    }}>
                        <ViewMore />
                    </Link>
                </div>
            </div>
            {/* <PortfolioLayout /> */}
        </React.Fragment>
    )
}

export default WebProjects;