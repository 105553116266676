import React, {useState, useEffect} from 'react';
import Loader from '../Single/Loader';
import {useHistory} from 'react-router-dom'

function MobileDetails(props){
    console.log(props)
    // console.log(props.location.state.nextIndex)
    // const indexArray = props.location.state.nextIndex
    // const props_id = props.location.pathname
    const project_new_id = props.location.state
    const [disableId, setDisableId]= useState(false)
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)

    const [projectId, setProjectId] = useState(props.location.state)
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/project/${project_new_id}`;
    let history = useHistory();
    
    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }

    useEffect(() => {
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setData(data)
            console.log(data)
            setLoader(false)
        }
        fetchData()
    }, [project_new_id])

    if(loader){
        return <Loader />
    }
    return(
        <div className="container" style={{maxWidth:"100%"}}>
            <div className="row project-detail-layout" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                <div className="col-12">
                    <h2 className="project-name">
                        {data.name}
                    </h2>
                    <h5 className="project-category">
                        {data.category}
                    </h5>
                    <p className="project-country">
                        {data.country}
                    </p>
                    <div style={{textAlign:"center"}}>
                        <img className="project-image padding-cut" id="mobile-project" src={data.images[3]} alt="image-1" />
                    </div>
                </div>
            </div>
            <div className="row project-detail-layout project-detail-layout2">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <div className="project-description-main">
                        <h6 className="project-description-heading">
                            Description
                        </h6>
                        <p className="project-description">
                            {data.description}
                        </p>
                    </div>
                </div>
                <div className="col-lg-2"></div>
                
                <div className="col-lg-12">
                    <img className="project-image-second" src={data.images[2]} alt="image-1" />
                </div>
                
                <div className="col-lg-2"></div>
                <div className="col-lg-8" style={{marginBottom:"15vh"}}>
                    <div className="project-description-main project-1vh">
                        <h6 className="project-description-heading">
                            Technologies
                        </h6>
                    </div>
                    <div className="project-technologies">
                        {data.technologies.map((d => (
                                <div className="project-tech" key={d}>{d}</div>
                        )))}
                    </div>
                </div>
                <div className="col-lg-2"></div>
                
            </div>
        </div>
    )
}

export default MobileDetails;