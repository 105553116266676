import React from 'react';


function Values(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-headings">
                        <h1>
                            Values
                        </h1>
                        <p>
                            Started from the bottom, now we here.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/value-1.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Team Work
                            </h2>
                            <p className="value-description">
                                We believe that the collaboration of different minds instead of one produces good results.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/value-6.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Integrity
                            </h2>
                            <p className="value-description">
                                We believe that if you are not honest with yourself you are not honest with anything.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/value-5.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Innovation
                            </h2>
                            <p className="value-description">
                                Our goal is to be innovative, to think different and to create a better future.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-2"></div> */}

                <div className="col-lg-6" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/value-4.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Customer Satisfaction
                            </h2>
                            <p className="value-description">
                                Our goal is to assist the customer till the last breath of their satisfaction.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/value-7.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Excellence
                            </h2>
                            <p className="value-description">
                                Our everyday goal is to enhance and produce the best outcome for your business idea.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-2"></div> */}


            </div>
        </div>
    )
}

export default Values;