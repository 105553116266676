import React, {useEffect, useState} from 'react'

function Test(){
    const [apiData, setApiData] = useState({});
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/`;
    useEffect(() => {
        fetch(apiUrl,{
            method:'GET',
            headers: new Headers({
                "Content-Type": "application/json",
                'Authorization': 'Basic ' + btoa(`${username}:${password}`),
            }),
        })
          .then((res) => res.json())
          .then((data) => setApiData(data));
      }, []);
    console.log(apiData)
    return(
        <div>
            Hello
        </div>
    )
}

export default Test;