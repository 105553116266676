import React from 'react';
import { Link } from 'react-router-dom';

import './checkmark.css'

function CheckBox(){
    return(
        <React.Fragment>
            <div className="checkmark-bigger-box">
                <div className="checkmark-box">
                    <img className="heartbeat" src={`${process.env.PUBLIC_URL}/img/icons/love.svg`} alt="sad"></img>
                    <p>
                        Your email was sent successfully. 
                    </p>
                    <Link to="/contact">
                        <button className="button-crud">
                            Okay
                        </button>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CheckBox