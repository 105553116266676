import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Single/Loader';

function WebsitePortfolio({match, location}){
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)
    const [indexArray, setIndexArray] = useState([])
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/projectsWithCategory/${location.state}`;
    useEffect(() => {
        document.title = "Sinecure | Website Development"
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setData(data)
            const tempArr = [];
            for(let i = 0; i < data.length; i++){
                tempArr.push(data[i].id)
            }
            setIndexArray(tempArr)
            setLoader(false)
        }
        fetchData()
    }, [])

    if(loader){
        return <Loader />
    }

    return(
        <div>
            <div className="container" style={{maxWidth:"100%"}}>
                <div className="row">
                    <div className="col-12" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                        <div className="portfolio-main-box" id="websitedevelopment">
                            <div className="row" style={{width:"100%"}}>
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                    <h1 className="portfolio-text">
                                        Website
                                    </h1>
                                    <h1 className="portfolio-text-second">
                                        Development<span className="portfolio-text-span">.</span>
                                    </h1>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container margins">
                <div className="row">
                    {data.map((d => (
                        <div className="col-lg-6" style={{paddingLeft:"8px", paddingRight:"8px"}} key={d.id}>
                            <Link to={{
                                pathname:`${match.url}/${d.id}`,
                                state:{
                                    id: `${d.id}`,
                                    nextIndex:indexArray
                                },
                            }}>
                                <div className="website-layout">
                                    <p>
                                        {d.category}
                                    </p>
                                    <h2>
                                        {d.name}
                                    </h2>
                                    <img src={d.images[0]} alt="websitelayouts" className="main-img" />
                                    <h6>
                                        Learn More
                                    </h6>
                                </div>
                            </Link>
                        </div>
                    )))}
                </div>
            </div>
        </div>
    )
}

export default WebsitePortfolio;