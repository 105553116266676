import React, {useState, useEffect} from 'react';
import Under from '../Single/Under';
import Loader from '../Single/Loader';

function Blogs(){
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <Under />
    )
}

export default Blogs;