import React from 'react';
import {Link} from 'react-router-dom';
import SectionTitle from '../../Single/SectionTitle';
import ViewMore from '../../Single/ViewMore';
import ArchitectureProject from './ArchitectureProject'
const architectureData = [
    {
        id:"arch00",
        imgURL:`${process.env.PUBLIC_URL}/img/icons/noor-2.webp`,
    },
    {
        id:"arch01",
        imgURL:`${process.env.PUBLIC_URL}/img/icons/noor-3.webp`,
    },
]

function Architecture(){
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <SectionTitle title="Architectural & 3D Design" paragraph="Sky is the limit, but architecture is not." />
                <div className="col-12" style={{paddingRight:"8px", paddingLeft:"8px", marginBottom:"16px"}}>
                    <img src={`${process.env.PUBLIC_URL}/img/icons/noor-1.webp`} style={{width:"100%"}} alt="architecture-1" />
                </div>
                {architectureData.map((i) => (
                    <div className="col-lg-6" key={i.id} style={{paddingLeft:"8px", paddingRight:"8px"}}>
                        <ArchitectureProject img={i.imgURL} id={i.id}/>
                    </div>
                ))}
                <div className="col-12">
                    <Link to={{
                        pathname:`portfolio/architecture`,
                        state:`Architectural Visualization`
                    }}>
                        <ViewMore />
                    </Link>
                </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Architecture;