import React, {useState, useEffect} from 'react';
import Loader from '../Single/Loader';



function ClientsComplete(){
    const [firstArray, setFirstArray] = useState([])
    const [secondArray, setSecondArray] = useState([])
    const [loader, setLoader]= useState(true)

    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/clients/`;

    useEffect(() => {
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setFirstArray(data.slice(0,5))
            setSecondArray(data.slice(5,10))
            setLoader(false)
        }
        fetchData()
    }, [])

    if(loader){
        return <Loader />
    }
    return(
        <div className="clients-complete">
            <div className="container">
                <div className="row">
                    <div className="clients-images">
                        {firstArray.map((d) => (
                            <div className="clients-div" key={d.id}>
                                <img src={d.logo} alt="client-image" />
                            </div>
                        ))}
                    </div>
                    <div className="clients-images">
                        {secondArray.map((d) => (
                            <div className="clients-div" key={d.id}>
                                <img src={d.logo} alt="client-image" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientsComplete;