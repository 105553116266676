import React, {useState, useEffect} from 'react';
import Loader from '../Single/Loader';
import ModalImage, {Lightbox} from "react-modal-image";
function GraphicPortfolio({match, location}){
    const [data, setData] = useState([])
    const [loader, setLoader]= useState(true)
    const username = "sinecureapis";
    const password = "sinecure@apis.com"
    const apiUrl = `https://apis.sinecure.xyz/projectsWithCategory/${location.state}`;
    useEffect(() => {
        document.title = "Sinecure | Graphic Design"
        async function fetchData(){
            const technologyData = await fetch(apiUrl, {
                method:"GET",
                headers:{
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`),
                },
            })
            const data = await technologyData.json();
            setData(data)
            setLoader(false)
        }
        fetchData()
    }, [])
    if(loader){
        return <Loader />
    }

    return(
        <React.Fragment>
            <div className="container" style={{maxWidth:"100%"}}>
                <div className="row">
                    <div className="col-12" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                        <div className="portfolio-main-box" id="graphic">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                <h1 className="portfolio-text">
                                    Graphic
                                </h1>
                                <h1 className="portfolio-text-second">
                                    Design<span className="portfolio-text-span">.</span>
                                </h1>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginBottom:"100px"}}>
                <div className="container">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="architecture">
                            <h6 className="architecture-heading">
                                Description
                            </h6>
                            <p className="architecture-description">
                                Cartoons, Illustrations and Graphic Design
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="row">
                        {data.map((d => (
                            <React.Fragment key={d.id}>
                                    {d.images.map((o => (
                                        <React.Fragment key={o}>
                                            <div className="col-lg-6 graphic" style={{paddingLeft:"8px", paddingRight:"8px"}} key={o}>
                                                <ModalImage 
                                                    small={o}
                                                    large={o}
                                                    hideDownload={true}
                                                    className="graphic-images"
                                                />
                                            </div>
                                        </React.Fragment>
                                    )))}
                            </React.Fragment>
                        )))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GraphicPortfolio;