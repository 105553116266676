import React from 'react';
import './careers.css';

function Jobs(){
    return(
        <div className="col-12">
            <div className="job-box">
                <h2 className="job-title">
                    UI UX Designer
                </h2>
                <h4 className="job-description-title">
                    Job Description
                </h4>
                <p className="job-description">
                    - Sinecure is searching for a UI/UX Designer to create and design User Interfaces and Experiences with latest tools.
                </p>
                <h4 className="job-description-title">
                    Responsibilities
                </h4>
                <p className="job-description">
                    - Strong understanding of information architecture, interaction design, and visual design.
                </p>
                <p className="job-description">
                    - Your portfolio has strong emphasis on web, mobile and tablet app designs.
                </p>
                <p className="job-description">
                    - Experience in making wire frames, interactive prototypes, icons and web/mobile design.
                </p>
                <div className="flex-end-careers">
                    <a href="mailto:careers@sinecure.xyz">
                        <button className="apply-now">Apply Now</button>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Jobs;