import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

function PortfolioMainTabs(props){
    const propsUrl = props.props.url;
    const data = props.data
    function checkCategoryType(d){
        const name = d.d.name
        if(name === "Website Development"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/websites`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-web.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else if(name === "Mobile App Development"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/mobile-apps`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-mobile.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else if(name === "Dev Ops Engineering"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/devops`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-devops.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else if(name === "Architectural Visualization"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/architecture`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-architecture.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else if(name === "UI UX Design"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/ui-ux`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-uiux.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else if(name === "Graphic Design"){
            return(
                <Link to={{
                    pathname:`${propsUrl}/graphic-design`,
                    state:`${name}`
                }}>
                    <div className="portfolio-box">
                        <img src={`${process.env.PUBLIC_URL}/img/icons/cat-icon-graphic.svg`} className="tech-icon-class" alt="web-icon" />
                        <h6 className="portfolio-technology-name">
                            {name}
                        </h6>
                    </div>
                </Link>
            )
        }
        else{
            return false;
        }
    }

    return(
        <div className="row">
            {data.map((d => (
                <React.Fragment key={d.id}>
                    <div className="col-lg-4">
                        {checkCategoryType({d, propsUrl})}
                    </div>
                </React.Fragment>
            )))}
        </div>
    )
}

export default PortfolioMainTabs;