import React from 'react';
import Sphere from '../Single/Sphere';
function Banner(){
    return(
        <div className="col-12" style={{paddingRight:"0px", paddingLeft:"0px" }}>
            <div className="center-of-screen blur-bg">
                <Sphere />
                <h1 className="text-focus-in">
                    Idea of your world<span>.</span> Execution in ours<span>.</span>
                </h1>
                <p className="animate-later">
                    Design and Development made great again.
                </p>
                <div className="arrow-down">
                    <img src={`${process.env.PUBLIC_URL}/img/arrow-down.png`} alt="icon_down" />
                </div>
            </div>
        </div>
    )
}

export default Banner;