import React, {useState} from 'react';
// import Home from './Components/Home/Home'
import Home from './Components/HomePage/Home'
import Toolbar from './Components/Toolbar/Toolbar'
import SideDrawer from './Components/SideDrawer/SideDrawer'
import Backdrop from './Components/BackDrop/Backdrop';
import {BrowserRouter as Router, Switch, Route, BrowserRouter} from 'react-router-dom';
import PortfolioMain from './Components/Portfolio2/Portfolio';
import About from './Components/About/About';
import Careers from './Components/Careers/Careers';
import Blogs from './Components/Blogs/Blogs';
import Contact from './Components/Contacts/Contact';
import SmallFooter from './Components/Single/SmallFooter';
import PortfolioMainLayout from './Components/Portfolio2/PortfolioMainLayout';
import PortfolioMainDetail from './Components/Portfolio2/PortfolioMainDetail';
import NotFound from './Components/Single/404';
import Test from './Components/Portfolio/test'
// import ContactUs from './Components/ContactUS/contactus';
import 'bootstrap/dist/css/bootstrap.min.css';
import PortfolioArchitectureLayout from './Components/Portfolio2/PortfolioArchitectureLayout';
import MobilePortfolio from './Components/Portfolio2/MobilePortfolio';
import MobileDetails from './Components/Portfolio2/MobileDetails';
import WebsitePortfolio from './Components/Portfolio2/WebsitePortfolio';
import WebsiteDetails from './Components/Portfolio2/WebsiteDetails';
import DevDetails from './Components/Portfolio2/DevDetails';
import DevPortfolio from './Components/Portfolio2/DevPortfolio';
import UIUXPortfolio from './Components/Portfolio2/UIUXPortfolio';
import UIUXDetails from './Components/Portfolio2/UIUXDetails';
import GraphicPortfolio from './Components/Portfolio2/GraphicPortfolio';
// import { set } from 'js-cookie';
// import Cookies from "js-cookie";
// import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink,ApolloLink } from '@apollo/client';

// // const client = new ApolloClient({
// //   uri:'http://127.0.0.1:8000/graphql/',
// //   credentials: 'same-origin',
// //   cache: new InMemoryCache(),
// // })

// let xmlHttp = new XMLHttpRequest();
// xmlHttp.open("GET", "192.168.10.10:3000/graphql/", false); // false for synchronous request
// xmlHttp.withCredentials = true;
// xmlHttp.send(null);
// const csrftoken = Cookies.get("csrftoken");
// const uri = `192.168.10.10:3000/graphql/`;
// const httpLink = createHttpLink({
//   uri,
//   credentials:'include'
// })

// const cache = new InMemoryCache();

// const AuthLink = (operation, next) => {
//   const token = csrftoken;
//   operation.setContext(context => ({
//     ...context,
//     headers: {
//       ...context.headers,
//       "X-CSRFToken": token
//     }
//   }));
//   return next(operation);
// };

// const link = ApolloLink.from([AuthLink, httpLink]);
// const client = new ApolloClient({
//   link:link,
//   cache
// })

function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  function drawerToggleClickHandler(){
    setSideDrawerOpen(!sideDrawerOpen)
  }
  function backdropClickHandler(){
    setSideDrawerOpen(!sideDrawerOpen)
  }
  let backdrop;

  if(sideDrawerOpen){
    backdrop = <Backdrop click={backdropClickHandler} />
  }
  return (
    <React.Fragment>
      <Router>
      <Toolbar drawerClickHandler={drawerToggleClickHandler} />
      <SideDrawer show={sideDrawerOpen} drawerClickHandler={drawerToggleClickHandler} />
      {backdrop}
      {/* <Home /> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/portfolio" exact component={PortfolioMain} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/careers" component={Careers} />
          <Route path="/portfolio/projects" exact component={PortfolioMainLayout} />

          <Route path="/portfolio/websites" exact component={WebsitePortfolio} />
          <Route path="/portfolio/websites/:id" exact component={WebsiteDetails} />

          <Route path="/portfolio/devops" exact component={DevPortfolio} />
          <Route path="/portfolio/devops/:id" exact component={DevDetails} />

          <Route path="/portfolio/architecture" exact component={PortfolioArchitectureLayout} />

          <Route path="/portfolio/graphic-design" exact component={GraphicPortfolio} />

          <Route path="/portfolio/mobile-apps" exact component={MobilePortfolio} />
          <Route path="/portfolio/mobile-apps/:id" exact component={MobileDetails} />

          <Route path="/portfolio/ui-ux" exact component={UIUXPortfolio} />
          <Route path="/portfolio/ui-ux/:id" exact component={UIUXDetails} />

          <Route path="/test" component={Test} />
          {/* <Route path="/portfolio/:id/:id" exact component={PortfolioMainDetail} /> */}
          <Route component={NotFound} />
        </Switch>      
        <SmallFooter />
      {/* <ApolloProvider client={client}>
        <ContactUs />
      </ApolloProvider> */}
      </Router>
    </React.Fragment>
  );
}

export default App;
