import React from 'react';


function Clients(){
    return(
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-headings">
                            <h1>
                                Clients & Reviews
                            </h1>
                            <p>
                                Real ones.
                            </p>
                        </div>
                    </div>
                    <div className="reviews-section">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-6" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                    <div className="review-img">
                                        <img src={`${process.env.PUBLIC_URL}/img/icons/reviews.webp`} alt="sapain-banner" />
                                    </div>
                                </div>
                                
                                <div className="col-lg-6" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                    <div className="review-text">
                                        <img src={`${process.env.PUBLIC_URL}/img/icons/sapian-logo.webp`} alt="sapian-logo" />
                                        <h6>
                                            Sinecure is committed to their time frame, highly professional in their work, has a high artistic touch when it comes to finalizing our tasks. As always, I'll always choose Sinecure for any software engineering project I have.
                                        </h6>
                                        <p>
                                            - Serge Maurice Lobreau, Founder & Chairman at SAPIAN GROUP
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Clients;