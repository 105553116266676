import React from 'react';


function backdrop(props){
    // console.log(props)
    return(
        <div className="backdrop" onClick={props.click}></div>
    )
}

export default backdrop;