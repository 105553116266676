import React from 'react';
import {Link} from 'react-router-dom';

function WebsiteProjectLayout(props){
    return(
        <Link to={`/portfolio/websites/${props.id}`}>
            <div className="website-layout">
                <p>
                    {props.category}
                </p>
                <h2>
                    {props.projectName}
                </h2>
                <img src={props.image} alt="websitelayouts" className="main-img" />
                <h6>
                    Learn More
                </h6>
            </div>
        </Link>
    )
}

export default WebsiteProjectLayout;