import React, {useState, useEffect} from 'react';
import './careers.css'
import Loader from '../Single/Loader';
import Jobs from './Jobs';
function Careers(){
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        document.title = "Sinecure | Careers"
        setTimeout(() => {
            setLoader(true)
        }, 2500)
    }, [loader])

    if(!loader){
        return <Loader />
    }
    return(
        <div className="careers">
            <div className="container" style={{maxWidth:"100%"}}>
                <div className="row">
                    <div className="careers-banner">
                    <h2 className="careers-tagline">
                        Ain't nothing like working at 
                        <span className="careers-span">Sinecure.</span>
                    </h2>
                    </div>
                </div>
            </div>
            <div className="container" style={{maxWidth:"100%", background:"#d9d9d9"}}>
                <div className="row">
                    <div className="careers-d-box">
                        <h2 className="careers-d-box-text">
                            We’re <span className="span-d-box-text">
                                Sinecure
                            </span>. Our talented, hardworking and fun-loving team is committed to <span className="span-d-box-text">bringing the world the Software & Design Excellence.</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-headings">
                            <h1>
                                Perks & Benefits
                            </h1>
                            <p>
                                Plain and simple. Nothing corporate.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-1.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Fuel Allowance
                            </h2>
                            <p className="value-description">
                                Don't be late.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-2.svg`} alt="value-image" />
                            <h2 className="value-text">
                                In-House Games
                            </h2>
                            <p className="value-description">
                                Work, play and work again.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-3.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Flexible Timing
                            </h2>
                            <p className="value-description">
                                Be an early bird or night owl.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-4.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Birthdays
                            </h2>
                            <p className="value-description">
                                Everyday is a celebration.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-5.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Biannual Tours
                            </h2>
                            <p className="value-description">
                                Preferrably Northern Areas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" style={{paddingLeft:"8px", paddingRight:"8px"}}>
                    <div className="value-box">
                        <div className="value-overlay"></div>
                        <div className="value-container">
                            <img className="value-icon" src={`${process.env.PUBLIC_URL}/img/icons/a-6.svg`} alt="value-image" />
                            <h2 className="value-text">
                                Gym Memberships
                            </h2>
                            <p className="value-description">
                                Work hard. Train hard.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-headings">
                            <h1>
                                Job Openings
                            </h1>
                            <p>
                                Sinecure is a stick, you can be the magician.
                            </p>
                        </div>
                    </div>
                    <Jobs />
                </div>
            </div>
        </div>
    )
}

export default Careers;