import React from 'react';


function TechPara(){
    return(
        <React.Fragment>
            <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <div className="tech">
                        <h4 style={{width:"68%"}}>
                        Sinecure is a software company that provides services for advance, extendable and efficient solutions!
                        </h4>
                    </div>
                </div>
                <div className="col-lg-2"></div>
        </React.Fragment>
    )
}

export default TechPara;