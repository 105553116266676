import React from 'react';
import {Link} from 'react-router-dom';

function NotFound(){
    return(
        <div className="error-page">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="error-main vibrate-1">
                            404
                        </h2>
                    </div>
                    <div className="col-12">
                        <p className="error-text">
                            Sinecure apologise for this inconvenience.
                        </p>
                        <p className="error-text">
                            Till then, you can check out portfolio or read our blogs.
                        </p>
                    </div>
                    <div className="col-lg-3"></div>
                    <div className="col-lg-3">
                        <div className="error-button-center">
                            <Link to="/portfolio">
                                <button className="error-button">
                                    Visit Portfolio
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="error-button-center">
                            <Link to="/blogs">
                                <button className="error-button">
                                    Visit Blogs
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;