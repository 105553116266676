import React from 'react';


function ArchitectureProject(props){
    return(
        <React.Fragment>
            <div className="arch" id={props.id}>
                <img src={props.img} alt="arch-img" />
            </div>
        </React.Fragment>
    )
}

export default ArchitectureProject;